<template>
  <div class="md:px-6 px-2 py-2 max-w-[100%]">
    <div class="px-2 md:py-4 flex flex-row justify-between items-center max-[575px]:px-0">
      <router-link
        to="/casino/group/originals/"
        class="flex items-center justify-start gap-2 py-0 md:py-4">
        <img class="h-8 sm:h-10 md:h-12" src="../../assets/sidebar/original.png" alt="" />
        <p class="font-semibold min-w-max text-md md:text-xl">Chanced Originals</p>
      </router-link>
      <div class="flex">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-l-xl hover:opacity-60 cursor-pointer">
          <!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd" />
          </svg>-->
          <img
            src="../../assets/3d/right-arrow.png"
            class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-r-xl hover:opacity-60 cursor-pointer">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div>
    <div ref="tiles" class="no-scrollbar home-casino-slider overflow-x-scroll py-2">
      <div
        v-for="(tile, i) in runebetOriginals"
        v-bind:key="i"
        class="slider-tile"
        :data-key="i">
        <router-link class="flex flex-col" :to="tile.link" :key="i">
          <img
            v-lazy="tile.img"
            class="flex flex-col items-center rounded-md shadow-md transition delay-0 duration-150 ease-in-out hover:rotate-1 hover:scale-105"
            alt="Game Tile" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
//import Crash from "../../assets/svg/plunder/Crash.png";
import Dice from '../../assets/svg/plunder/Dice.png';
import Roulette from '../../assets/svg/plunder/Roulette.png';
import Slots from '../../assets/svg/plunder/Slots.png';
import Mines from '../../assets/svg/plunder/Mines.png';
import Plinko from '../../assets/svg/plunder/Plinko.png';
import Blackjack from '../../assets/svg/plunder/Blackjack.png';
import Keno from '../../assets/svg/plunder/Keno.png';
import Baccarat from '../../assets/svg/plunder/Baccarat.png';
import Limbo from '../../assets/svg/plunder/Limbo.png';

import { clamp } from 'lodash';
export default {
  name: 'Tiles',
  setup() {
    const runebetOriginals = [
      // { img: Bank, link: "?tab=bank", name: "Bank" },
      //{ img: Crash, link: "/casino/crash", name: "Crash" },
      //{ img: Blackjack, link: '/casino/blackjack', name: 'Blackjack' },
      // { img: Plinko, link: "/casino/plinko", name: "Plinko" },
      { img: Baccarat, link: '/casino/baccarat', name: 'Baccarat' },
      { img: Limbo, link: '/casino/limbo', name: 'Limbo' },
      //{ img: Keno, link: '/casino/keno', name: 'Keno' },
      { img: Dice, link: '/casino/dice', name: 'Dice' },
      { img: Roulette, link: '/casino/roulette', name: 'Roulette' },
     // { img: Mines, link: '/casino/mines', name: 'Mines' },
      // { img: War, link: "#", name: "War(Coming Soon)" },
      // ß{ img: Slots, link: "#", name: "Slots(Coming Soon)" } /*/casino/slots*/,
      // { img: Duels, link: "#", name: "Duels(Coming Soon)" } /*/casino/duels*/,
    ];
    return { runebetOriginals };
  },
  data() {
    return { scrollAmount: 0, currentIndex: 0 };
  },
  methods: {
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs.tiles;

      const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;

      /*sroll_amount_increase = menu.clientWidth  -  (sroll_amount_increase   + 1  )  ; 
      this.scrollAmount = clamp(
        isRight ? (this.scrollAmount += sroll_amount_increase) : (this.scrollAmount -= sroll_amount_increase),
        0,
        width
      );*/
      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
    setFade(isRight = true) {
      const menu = this.$refs.tiles;

      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('slider-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
          ? this.currentIndex + visible_item
          : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
          '.slider-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
          menu.getElementsByClassName('slider-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .tiles-main {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
}

@media (min-width: 769px) {
  .tiles-main {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.tiles-main {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  gap: 2rem;
  grid-auto-rows: minmax(min-content, max-content);
}
</style>
