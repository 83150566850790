<template>
 
  
          <div v-if="!bets || bets.length ===0 " class="flex w-[100%] flex-row items-center justify-center text-center w-full">
            <div class="pt-2">
              <svg
                class="h-10"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve">
                <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </div>
             
          </div>
     
        <router-link :href="getLink(bet)" :to="getLink(bet)" v-for="(bet, index) in bets" v-bind:key="bet.id"  class="card flex flex-row gap-2 w-36 rounded-md bg-secondary-light p-2 overflow-hidden" :class="[ { 'animate-zoomIn': index === 0 }]">
        
            <div >
              <img 
              :src="getImageUrl(bet)" 
              @error="loadFallbackImage"
              :data-fallback="getOriginalImage(bet)"
              class="w-10 h-10 max-w-none object-cover rounded-md overflow-hidden" alt="image">
            </div>
            <div class="flex flex-col gap-1 ">
            <p class="truncate text-[10px] font-bold uppercase text-white/60">{{(bet.user!=undefined )?bet.user.username:''}}</p>
            <div class="flex flex-row gap-x-2">
                <img alt="icon" :src="getIcon(bet.game_mode)" class="h-4 w-4" />
                <p class="text-xs font-semibold text-lime-600" :class="{ 'scale-[1.3] animate-scale': index === 0 }">{{bet.profit}}</p>
            </div>
            </div>
    
        </router-link>
   
</template>
<style>
    @keyframes zoomIn {
      0% {
        min-width: 0px;
        width: 0;
      }
      100% {
        width:calc(100%/var(--flexx-items) - ((var(--flexx-items) - 1)/var(--flexx-items))*var(--flexx-gap))
      }
    }
    .animate-zoomIn {
      animation: zoomIn 0.2s forwards;
    }
    @keyframes scalea {
      0% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(1);
      }
    }
    .animate-scale {
      animation: scalea 0.3s 0.2s forwards ease-in;
    }
    .tabSlider .card{
      width:calc(100%/var(--flexx-items) - ((var(--flexx-items) - 1)/var(--flexx-items))*var(--flexx-gap));
      min-width:calc(100%/var(--flexx-items) - ((var(--flexx-items) - 1)/var(--flexx-items))*var(--flexx-gap))
    }
   
    @media screen and (max-width:1000px){
      .card{
        width:144px;
        min-width: 144px;
      }
    }
   
  </style>
<script>
import { getBalanceIcon } from '@/lib/icons';
import axios from '@/lib/myAxios';
export default {
    name:'WinTab',
    props:{
      type:{
        default:'24h'
      }
    },
    data() {

        return {
            bets:  [  ], historyData: [],latestCardIndex: null, loading: true
        };
    },
    async created(){

      if(this.type == 'live'  )
      {
          window.Echo.channel('game-history').listen('BetResultsBulk', ({ history }) => {
            const existing = this.historyData;
            history = history.filter((item)=>{
              return (item.profit > 0);
            });
            this.historyData = [...history, ...existing];
            
            if (this.historyData.length > 60) {
              this.historyData = this.historyData.slice(0, 60);
            }
          });

          setInterval(() => {
          if (this.historyData.length > 0) {
          
            let historyRow = this.historyData.shift();
            if (historyRow.profit > 0) {
              
                  
              /*
              while(historyRow.timestamped < (new Date().setSeconds(0)/1000))
              {
                historyRow = this.historyData.shift();
              }*/
              historyRow.isNew=true;
              this.bets = [{...historyRow},...this.bets.slice(0, 8)];
              
            }
          } else {
            // this.fetchHistory('all');
          }
        }, 1000);
      }
      
    },
    async   mounted()
    {
      const data = await this.getFirstBets();
     
      this.bets = data.data;
    },
    methods: {
        getIcon(currency) {
            return getBalanceIcon(currency);
        },
        async getFirstBets()
        {
          return  await axios.get('getwins/'+this.type);
        },
        getImageUrl(bet) {
          return `${location.protocol}//${location.host}/images/${bet.image}`;
        },
        loadFallbackImage(event) {
          if (event.target.src != event.target.getAttribute('data-fallback'))
            event.target.src = event.target.getAttribute('data-fallback');
          else return false;
        },
        getIdentifierName(gameName) {
          return gameName.split(':')[1]?gameName.split(':')[1]:'';
        },
        getOriginalImage(game) {
          if (game.provider_slug === 'runebet') {
              const original = originals.find((o) => o.title === game.game_name);
              return original.img;
          }

          if (game.software == 'softswiss') {
            
            const identifier = this.getIdentifierName(game.identifier);

            return `https://cdn.softswiss.net/i/s4/${game.provider_slug}/${identifier}.png`;
          }
          return `${location.protocol}//${location.host}/images/default.webp`;
        },
        getLink(game) {
          if (game.provider_slug === 'runebet') {
            const original = originals.find((o) => o.title === game.game_name);
            if (original) {
              return original.link;
            }
          }
          if (game.software == 'relax') {
            // const gameName = this.getIdentifierName(game.identifier);
            return `/casino/games/${game.provider_slug}-${game.identifier}`;
          }

          if (game.software == 'softswiss') {
            const gameName = this.getIdentifierName(game.identifier);
          
            
            return `/softswiss/casino/games/${game.provider_slug}-${gameName}`;
          }
          if (game.software == 'pragmatic') {
            return `/pragmatic/casino/games/${game.identifier}`;
          }
        },
    },
     

};
</script>
  