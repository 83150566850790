<template>
  <div
    class="flex justify-center w-full h-full md:fixed md:top-0 md:bottom-0 md:left-0 md:right-0 bg-[rgb(55_65_81_/_40%)] backdrop-filter backdrop-blur-[3px] max-[575px]:h-[calc(100%_-_125px)]"
    style="z-index: 1000000"
    v-on:click="generalS.showSearch = false">
    <div
      class="h-full w-full text-center md:mt-[6%] md:mr-[2%] md:h-[75%] md:w-[60%] md:rounded-lg md:bg-primary"
      v-on:click.stop>
      <div class="flex flex-col w-full h-full max-h-full px-4 py-2 md:px-8 md:py-4">
        <div class="relative w-full mt-4 md:mt-8">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                fill-rule="evenodd"></path>
            </svg>
          </div>
          <input
            v-model="searchText"
            class="input-search"
            placeholder="Search your game"
            type="text"
            v-on:change="debouncedSearch" />
        </div>
        <perfect-scrollbar
          class="flex-1 w-full"
          v-if="games.length > 0 || loading"
          ref="searchScroll">
          <div class="flex items-center justify-center h-5 pt-6" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </div>
          <div
            class="grid w-full grid-cols-3 gap-6 mt-4 grid-rows-auto md:grid-cols-5 max-[767px]:gap-3">
            <router-link
              v-on:click="generalS.showSearch = false"
              class="flex flex-col transition duration-150 ease-in-out delay-0 hover:rotate-1 hover:scale-105"
              v-for="(game, i) in games"
              :key="i"
              :to="getLink(game)">
              <img
                :src="getImageUrl(game)"
                @error="loadFallbackImage"
                :data-fallback="getOriginalImage(game)"
                alt="Game Tile"
                v-tooltip="game.title"
                class="rounded-md shadow-md w-28 md:w-56 max-[767px]:w-[100%]" />
            </router-link>
          </div>
        </perfect-scrollbar>
        <div
          class="flex items-center justify-center flex-1 w-full h-full text-lg font-bold text-slate-300/70"
          v-else>
          <div class="w-full" v-if="noResult">
            <svg
              width="96"
              height="96"
              fill="none"
              class="mx-auto mb-6 text-slate-300/70">
              <path
                d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
              <ellipse
                cx="37.5"
                cy="43.5"
                rx="4.5"
                ry="7.5"
                fill="currentColor"></ellipse>
              <ellipse
                cx="58.5"
                cy="43.5"
                rx="4.5"
                ry="7.5"
                fill="currentColor"></ellipse>
              <path
                d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
              <path
                d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
                fill="currentColor"></path>
            </svg>
            Sorry! There are no results for “{{ searchText }}”.
          </div>
          <div v-else>
            <svg
              class="py-6 mx-auto h-28 w-28 text-slate-300/70"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                fill-rule="evenodd"></path>
            </svg>
            Type at least 3 letters to start searching
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generalStore } from '@/store/pinia/generalStore';
// import { softswissStore } from "@/store/pinia/softswissStore";
import { relaxStore } from '@/store/pinia/relaxStore';
import { debounce } from 'lodash';
// import originals from "@/components/Softswiss/originals";
import originals from '@/components/Relax/originals';

export default {
  name: 'Search',

  setup() {
    const generalS = generalStore();
    // const softS = softswissStore();
    const relaxS = relaxStore();
    return { generalS, relaxS };
  },
  created() {
    this.debouncedSearch = debounce(this.search, 300, {
      leading: false,
      trailing: true,
    });
  },
  data() {
    return {
      games: [],
      noResult: false,
      searchText: '',
      debouncedSearch: null,
      loading: false,
    };
  },
  methods: {
    getImageUrl(game) {
      if (game.provider === 'runebet') {
        const original = originals.find((o) => o.title === game.title);
        return original.img;
      }

      if (game.software == 'relax') {
        return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
      }

      if (game.software == 'softswiss') {
        const identifier = this.getIdentifierName(game.identifier);

        return `${location.protocol}//${location.host}/images/softswiss/${identifier}.webp`;
      }
      if (game.software == 'pragmatic') {
        return `${location.protocol}//${location.host}/images/pragmatic/${game.identifier}.webp`;
      }
      return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
    },
    loadFallbackImage(event) {
      if (event.target.src != event.target.getAttribute('data-fallback'))
        event.target.src = event.target.getAttribute('data-fallback');
      else return false;
    },
    getOriginalImage(game) {
      if (game.software == 'softswiss') {
        if (game.provider === 'runebet') {
          const original = originals.find((o) => o.title === game.title);
          return original.img;
        }
        const identifier = this.getIdentifierName(game.identifier);

        return `https://cdn.softswiss.net/i/s4/${game.provider}/${identifier}.webp`;
      }
      return `${location.protocol}//${location.host}/images/default.webp`;
    },
    getLink(game) {
      if (game.provider === 'runebet') {
        const original = originals.find((o) => o.title === game.title);
        return original.link;
      }
      if (game.software == 'relax') {
        // const gameName = this.getIdentifierName(game.identifier);
        return `/casino/games/${game.provider}-${game.identifier}`;
      }

      if (game.software == 'softswiss') {
        const gameName = this.getIdentifierName(game.identifier);
        return `/softswiss/casino/games/${game.provider}-${gameName}`;
      }
      if (game.software == 'pragmatic') {
        return `/pragmatic/casino/games/${game.identifier}`;
      }
    },
    getIdentifierName(gameName) {
      return gameName.split(':')[1];
    },
    async search() {
      if (this.searchText && this.searchText.length >= 3) {
        try {
          this.loading = true;
          const { data } = await this.relaxS.fetchAll({
            search: this.searchText,
          });
          this.games = data.results.data;
          this.noResult = this.games.length === 0;
        } catch (e) {
          this.noResult = false;
        } finally {
          this.loading = false;
          //this.$refs.searchScroll.$el.scrollTop = 0;
        }
      } else {
        this.games = [];
        this.noResult = false;
        //this.$refs.searchScroll.$el.scrollTop = 0;
      }
    },
  },
  watch: {
    searchText() {
      this.debouncedSearch();
    },
  },
};
</script>

<style scoped>
.modal {
  text-align: center;
  width: 60%;
  height: 75%;
  margin-top: 6%;
  margin-right: 2%;
  border-radius: 10px;
}
</style>
