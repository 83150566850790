import { format, parse } from './converter';
import accounting from 'accounting';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as Sentry from '@sentry/vue';
import router from '@/router';

export const randomString = function (length = 36) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const twoX = function (amount) {
  let parsed = parse(amount) * 2;
  return format(parseInt(parsed.toString()));
};

export const halfX = function (amount) {
  let parsed = parse(amount) * 0.5;
  return format(parseInt(parsed.toString()));
};

export const delay = function (duration = 300) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

export const shuffleArray = function (min, max) {
  let array = Array(max)
    .fill()
    .map((_, i) => i + min);
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const randomInteger = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const playAudio = function (audio, muted = false) {
  if (!muted) {
    audio
      .play()
      .then(() => {})
      .catch((e) => {});
  }
};

export const copyToClipboard = function (text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    let textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return prompt('Copy to clipboard: Ctrl+C, Enter', text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const isLocal = function () {
  return import.meta.env.VITE_API_BASE_URL === 'http://localhost:8000';
};

export const recaptcha = function (action) {
  const grecaptcha = window.grecaptcha;
  const sitekey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      try {
        grecaptcha.execute(sitekey, { action }).then(resolve);
      } catch (e) {
        reject(e);
      }
    });
  });
};

export const abbrNumber = function (number, decimals = 3) {
  if (isNaN(number)) return 8;
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(decimals) + 'K';
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(decimals) + 'M';
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(decimals) + 'B';
  if (number >= 1e12) return +(number / 1e12).toFixed(decimals) + 'T';
};

const truncateDecimals = function (value, precision) {
  var step = Math.pow(10, precision || 0);
  var temp = Math.trunc(step * value);

  return temp / step;
};

export function getCryptoDecimalPlaces(number) {
  if (isNaN(number)) return '';
  if (number < 1e3) return 8;
  if (number < 1e5) return 5;
  if (number <= 1e7) return 4;
  if (number <= 1e9) return 4;
  if (number >= 1e9) return 2;
}

export const formatFloat = function (number, decimals = 12) {
  if (isNaN(number)) return '';
  return truncateDecimals(parseFloat(number), decimals).toString(); //.replace(/(\.0+|0+)$/,  "");
};

export function formatCrypto(amount, isFiat = false) {
  const [places, truncatedAmount] = getTruncatedAmount(amount, isFiat);

  return accounting.formatNumber(truncatedAmount, places, ',');
}

export function formatRedemptionAmount(amount) {
  return accounting.formatNumber(accounting.toFixed(parseFloat(amount), 2), 2, ',');
}

export function formatCryptoRaw(amount, isFiat = false) {
  const [places, truncatedAmount] = getTruncatedAmount(amount, isFiat);

  return accounting.formatNumber(truncatedAmount, places, '');
}

export function getTruncatedAmount(amount, isFiat = false) {
  const places = !isFiat ? getCryptoDecimalPlaces(amount) : 2;
  const factor = Math.pow(10, places);
  const truncatedAmount = Math.floor(amount * factor) / factor;
  return [places, truncatedAmount];
}

export function convertToSlug(str) {
  return str;
  if (!str) return '';
  str = str.match(/[A-Z][a-z]+/g).join(' ');
  //replace all special characters | symbols with a space
  str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ');
  //.toLowerCase();

  // trim spaces at start and end of string
  str = str.replace(/^\s+|\s+$/gm, '');

  // replace space with dash/hyphen
  str = str.replace(/\s+/g, '-');
  return str;
}
export const addQueryParam = (key, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
};

export const abortBlocked = () => {
  setTimeout(() => {
    const path = '/?tab=blocked';
    if (!isBlocked()) {
      window.location.replace(path);
    }
  }, 1500);
};

export const isBlocked = () => {
  const url = window.location.href;
  const path = '?tab=blocked';
  return url.includes(path);
};

export const setGlobalFp = () => {
  setTimeout(() => {
    const fpPromise = FingerprintJS.load({ monitoring: false });
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        window.fp = result.visitorId;
      });
  }, 1.5e3);
};

export const openFullscreen = (id) => {
  const elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};
export function fraudAndLocationPassed(result) {
  let countryPassed = result.country.passed ?? false;
  let finalResult = countryPassed;
  let notAllowedUsStates = ['WA', 'ID', 'MI'];
  let notAllowedCanadaStates = ['QC'];

  if (result.country.code === 'US' || result.country.code === 'CA') {
    if ('state' in result) {
      let statePassed = true;
      //let statePassed = result.state.passed ?? false;

      //Don't allow some states of USA and Canada to access the website
      if (
        notAllowedUsStates.indexOf(result.state.code) !== -1 ||
        notAllowedCanadaStates.indexOf(result.state.code) !== -1
      ) {
        statePassed = false;
      }

      finalResult = finalResult && statePassed;
    }
  }

  return finalResult;
}

export function proxyLocation(result) {
  return false; //Todo: proxy location check is disabled as of now
  return result.fraud.proxy ?? false;
}

export const sentryConfig = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: ["RadarLocationError",'#cc-number','NotSupportedErro'],
  allowUrls: [/https?:\/\/((cdn|www)\.)?chanced\.com/,/https?:\/\/((cdn|www)\.)?gambleh\.com/],
  integrations: [
    Sentry.browserTracingIntegration({
      router,
      enableInp: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),

  ],

  // Set tracesSampleRate to 0.01 to capture 1% of transactions for performance monitoring.
  tracesSampleRate: 0.3,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://chanced.com', 'https://gambleh.com'],

  // Set replaysOnErrorSampleRate to 0.1 to capture replays for 10% of errors.
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.2,
};



 

  

export function  setCookie  (cname, cvalue, seconds) {
      console.log(cname);
      const d = new Date();
      d.setTime(d.getTime() + ( seconds *1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function  deletCookie  (cname) {
  document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" 
}
export   function getCookie (cname)  {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
  
  

 
 
