<template>
  <div class="md:px-6 px-2 py-2 max-w-[100%]">
    <div class="flex flex-row items-center justify-between px-2 md:py-4 max-[575px]:px-0">
      <router-link
        to="/casino/group/live"
        class="flex items-center justify-start gap-2 py-0 md:py-4">
        <img class="h-8 sm:h-10 md:h-12" src="../../assets/sidebar/livec.png" alt="" />
        <p class="font-semibold min-w-max text-md md:text-xl">Live Dealers</p>
      </router-link>
      <div class="flex">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border cursor-pointer border-slate-600 rounded-l-xl hover:opacity-60">
          <!--          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd" />
          </svg>-->
          <img
            src="../../assets/3d/right-arrow.png"
            class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border cursor-pointer border-slate-600 rounded-r-xl hover:opacity-60">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div>
    <div ref="tiles" class="py-2 overflow-x-scroll no-scrollbar home-casino-slider">
      <div
        v-for="(game, i) in LiveDealer"
        v-bind:key="i"
        class="slider-tile"
        :data-key="i">
        <router-link
          class="transition duration-200 ease-in-out hover:opacity-80 delay-0 hover:scale-105 hover:rotate-1"
          :to="getLink(game)"
          :key="i">
          <img
            v-lazy="getImageUrl(game)"
            alt="Game Tile"
            v-tooltip="game.name"
            class="flex flex-col items-center transition duration-150 ease-in-out rounded-md shadow-md delay-0 hover:rotate-1 hover:scale-105" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { clamp } from 'lodash';
export default {
  name: 'HomeLiveTile',
  setup() {
    const LiveDealer = [
      // { img: Bank, link: "?tab=bank", name: "Bank" },
      //{ img: Crash, link: "/casino/crash", name: "Crash" },
      {
        identifier: 'launch_main_ssbj_01',
        provider: 'beterlive',
        name: 'Live Gravity Blackjack',
      },
      {
        identifier: 'launch_main_rol_01',
        provider: 'beterlive',
        name: 'Live Roulette',
      },
      {
        identifier: 'launch_asia_mbal_01',
        provider: 'beterlive',
        name: 'Live Baccarat',
      },
      {
        identifier: 'launch_main_rofl_01',
        provider: 'beterlive',
        name: 'Live French Roulette',
      },
      {
        identifier: 'launch_in_botp_1',
        provider: 'beterlive',
        name: 'Teen Patti',
      },
      {
        identifier: 'launch_auto_rol_01',
        provider: 'beterlive',
        name: 'Auto Roulette',
      },
    ];
    return { LiveDealer };
  },
  data() {
    return { scrollAmount: 0, currentIndex: 0 };
  },
  methods: {
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs.tiles;

      const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;

      /*sroll_amount_increase = menu.clientWidth  -  (sroll_amount_increase   + 1  )  ; 
      this.scrollAmount = clamp(
        isRight ? (this.scrollAmount += sroll_amount_increase) : (this.scrollAmount -= sroll_amount_increase),
        0,
        width
      );*/
      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
    setFade(isRight = true) {
      const menu = this.$refs.tiles;

      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('slider-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
          ? this.currentIndex + visible_item
          : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
          '.slider-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
          menu.getElementsByClassName('slider-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
    getImageUrl(game) {
      return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
    },
    getLink(game) {
      // const gameName = this.getIdentifierName(game.identifier);
      return `/casino/games/${game.provider}-${game.identifier}`;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .tiles-main {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
}

@media (min-width: 769px) {
  .tiles-main {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.tiles-main {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  gap: 2rem;
  grid-auto-rows: minmax(min-content, max-content);
}
</style>
