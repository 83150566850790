<template>
  <landing v-if="!this.isLoggedIn && this.landingpage" />
  <div
    v-else
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 md:p-4 items-center child:max-w-[1200px]">
    <div class="grid grid-cols-1 py-4 w-full max-[480px]:p-0">
      <home-slider />
      <bet-wins />
      <auth v-if="false" />
      <relax-home-tiles
        v-for="(cat, i) in relaxCategories"
        :key="i"
        :categories="cat.categories"
        :games="games"
        :icon="cat.icon"
        :slug="cat.slug"
        :title="cat.title" />
      <HomeLiveTile />
      <QuickBuy />

      <GameHistoryTable />
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import Landing from '@/components/Home/Landing.vue';
import Tiles from '@/components/Home/Tiles.vue';
import HomeLiveTile from '@/components/Home/HomeLiveTile.vue';
import Vip from '@/components/Home/Vip.vue';
import BetWins from '@/components/Home/BetWins.vue';
import GameType from '@/components/Home/GameType.vue';
import QuickBuy from '@/components/Home/QuickBuy.vue';
import GameHistoryTable from '../GameHistory/BetsHistory.vue'; 

import { mapGetters } from 'vuex';
// import SoftswissTiles from "@/components/Softswiss/SoftswissTiles.vue";
import RelaxTiles from '@/components/Relax/RelaxTiles.vue';
import SoftswissHomeTiles from '@/components/Softswiss/SoftwissHomeTiles.vue';
import RelaxHomeTiles from '@/components/Relax/RelaxHomeTiles.vue';
import HomeSlider from '@/components/Home/Slider/HomeSlider.vue';
import { softswissStore } from '@/store/pinia/softswissStore';
import { relaxStore } from '@/store/pinia/relaxStore';
import Search from '../Search/Search.vue';
import { generalStore } from '@/store/pinia/generalStore';

import { icons } from '../Shared/icons';

export default {
  name: 'Home',
  components: {
    HomeSlider,
    SoftswissHomeTiles,
    // SoftswissTiles,
    RelaxHomeTiles,
    RelaxTiles,
    QuickBuy,
    Tiles,
    HomeLiveTile,
    Auth,
    Vip,
    GameType,
    Landing,
    GameHistoryTable,
    Search,
    BetWins
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage']),
  },
  setup() {
    const softswissCategories = [
      {
        title: 'Slots',
        icon: icons.Slots,
        slug: 'slots',
        categories: ['slots'],
        options: {},
      },
      {
        title: 'Live Casino',
        icon: icons.Live,
        slug: 'live',
        categories: [],
        options: { live: true },
      },
      {
        title: 'Table Games',
        icon: icons.TableGames,
        slug: 'table-games',
        categories: ['cards', 'roulette', 'blackjack'],
        options: {},
      },
      {
        title: 'Poker',
        icon: icons.Poker,
        slug: 'poker',
        categories: ['poker'],
        options: {},
      },
    ];
    const relaxCategories = [
      {
        title: 'Slot Games',
        icon: icons.Slots,
        slug: 'slots',
        categories: ['slots'],
        options: {},
      },
    ];
    // const softS = softswissStore();
    const relaxS = relaxStore();

    const softS = softswissStore();
    const generalS = generalStore();
    return {
      softswissCategories,
      relaxCategories,
      softS,
      relaxS,
      generalS,
    };
  },
  async created() {
    await this.init();
  },
  data() {
    return {
      loading: true,
      games: [],
    };
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        // this.games = await this.softS.fetchFeatured();
        this.games = await this.relaxS.fetchFeatured();
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.aid': {
      handler: function (newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
